@if(userType === 'providers' || (guideLine === true || guideLine === false)){
<section class="flex flex-column gap-4">
    <div class="provider-details">
        <span class="outer-circle">
            <figure class="inner-circle">
                <img src="/assets/img/provider-img.svg" alt="provider-img" srcset="">
            </figure>
        </span>

        <span class="text-3xl font-medium">{{ dialogData.name }}</span>
        <span class="flex align-items-center gap-3 line-height-2 " style="color: #939393;">
        <p-rating [(ngModel)]="value" [readonly]="true" [cancel]="false"></p-rating>
        <span>{{dialogData?.avgRating ? dialogData?.avgRating : 0}} ( {{dialogData?.ratingCount ? dialogData?.ratingCount : 0 }} Reviews)</span>
        </span>
        <span class="flex align-items-center gap-3 line-height-4 font-medium">
            <i class="material-icons" style="color: var(--bcd-textBlack);">mail</i>
            {{ dialogData.email }}
        </span>
        <span class="text-xl line-height-2  font-semibold">
            Rate/Hour:
            <small class="text-lg">
                ${{ dialogData.ratePerHour }}
            </small>
        </span>
    </div>
    <div class="provider-details-about">
        <p class="font-semibold line-height-3">
            ABOUT: <br>
            <span class="text-base line-height-1 font-medium">{{dialogData.about}}</span>
        </p>
    </div>

</section>
}

<!-- Dialog for Customer -->
@if(userType === 'customers'){
<section class="flex flex-column customer-details">
    <div class="flex flex-column gap-1 pt-3">
        <p>Customer Name
            <span>{{ dialogData.name }}</span>
        </p>
        <p>Phone
            <span>{{ dialogData.mobileNumber }}</span>
        </p>
        <p>Email
            <span>{{ dialogData.email }}</span>
        </p>
        <p>State
            <span>{{ dialogData.state }}</span>
        </p>
        <p>City
            <span>{{ dialogData.city }}</span>
        </p>
        <p>Zip
            <span>{{ dialogData.zip }}</span>
        </p>
        <p>Address
            <span>{{dialogData.address}}</span>
        </p>
    </div>
</section>
}
