import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RatingModule } from 'primeng/rating';

@Component({
  selector: 'app-provider-details-dialog',
  standalone: true,
  imports: [
    RatingModule,
    FormsModule
  ],
  templateUrl: './provider-details-dialog.component.html',
  styleUrl: './provider-details-dialog.component.css',
//     template: `
//   <p>{{ data.headertitle }}</p>
// `
})
export class ProviderDetailsDialogComponent implements OnInit{

  value: number = 0;
  public dialogData:any;
  public userType: string = '';
  public guideLine: boolean = true;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.dialogData = this._dialogConfig.data;
    this.value = this.dialogData?.avgRating ? this.dialogData?.avgRating : 0;
    this._activatedRoute.queryParams.subscribe((res: any) => {
      if (res) {
        if (res.userType) {
          this.userType=res.userType;
          this.guideLine = res.guideline
        }
      }
    });
  }

}
